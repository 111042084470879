// extracted by mini-css-extract-plugin
export var backgroundColor = "index-module--backgroundColor--CsSku";
export var backgroundImage = "index-module--backgroundImage--dBWSg";
export var bravoSubTitle = "index-module--bravoSubTitle--73cQQ";
export var bravoTitleWrapper = "index-module--bravoTitleWrapper--Ghtog";
export var bravoWrapper = "index-module--bravoWrapper--BzUm8";
export var button = "index-module--button--lIlM0";
export var buttonWrapper = "index-module--buttonWrapper--ewcra";
export var contentWrapper = "index-module--contentWrapper--HYG94";
export var copyrightWrapper = "index-module--copyrightWrapper--Ekbpy";
export var logo = "index-module--logo--ItZjJ";
export var marginBottom = "index-module--marginBottom--M3lQ2";
export var pageWrapper = "index-module--pageWrapper--navCc";
export var share = "index-module--share--+LYoC";
export var subTitle = "index-module--subTitle--XLM+G";
export var titleWrapper = "index-module--titleWrapper--h7aRg";
export var upperWrapper = "index-module--upperWrapper--2eIym";
export var whiteBox = "index-module--whiteBox--JCCzB";