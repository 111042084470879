import React from "react";
import * as styles from "./parts.module.scss";

interface Props {
  content: string;
  alignLeft?: boolean;
}

export const Title: React.FC<Props> = ({ content, alignLeft }) => (
  <div
    className={styles.title}
    style={{ textAlign: (alignLeft && "left") || undefined }}
  >
    {content}
  </div>
);
