import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { usePageContext } from "../components/Context/pageContext";
import { Layout } from "../components/Layout/layout";
import { Button } from "../components/Parts/button";
import { Copyright } from "../components/Parts/copyright";
import { Logo } from "../components/Parts/logo";
import { Title } from "../components/Parts/title";
import SEO from "../components/seo";
import "../styles/globals.scss";
import * as styles from "../styles/index.module.scss";

const IndexPage: React.FC<any> = ({ data: { home } }) => {
  const { lang } = usePageContext();

  return (
    <Layout>
      <SEO title="Accueil" />
      <div className={styles.pageWrapper}>
        <div className={styles.whiteBox}>
          <div className={styles.contentWrapper}>
            <div className={styles.upperWrapper}>
              <div className={styles.logo}>
                <Logo />
              </div>
              <div className={styles.subTitle}>{home.subtitle[lang]}</div>
              <div className={styles.titleWrapper}>
                <Title content={home.content[lang]} />
              </div>
            </div>
            <Button
              content={home.button[lang]}
              destination="/visual"
              clickable={true}
            />
            <div className={styles.copyrightWrapper}>
              <Copyright />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query home {
    home: sanityApp {
      button: homeButton {
        en
        fr
      }
      content: homeContent {
        en
        fr
      }
      subtitle: homeSubtitle {
        en
        fr
      }
    }
  }
`;

export default IndexPage;
