import React, { ReactNode } from "react";
import "../../styles/globals.scss";
import { Copyright } from "../Parts/copyright";
import * as styles from "./layout.module.scss";

interface Props {
  children: ReactNode;
}

export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.backgroundImage}>
      {children}
      <div className={styles.copyright}>
        <Copyright />
      </div>
    </div>
  );
};
